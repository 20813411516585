<template>
  <div class="modal-info-box" :class="getClass()">
    <slot />
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
  align?: 'center' | 'left' | 'right',
  scroll?: boolean,
}>(), {
  align: 'center',
  scroll: true,
});
const getClass = () => {
  const classNames = [`text-${props.align}`];
  if (props.scroll) {
    classNames.push('overflow-y-auto');
  }
  return classNames;
};
</script>

<style lang="scss" scoped>
/**
 * モーダル内のスクロール
 */
.modal-info-box {
  border-image: url("~/assets/images/common/common_status_bg_3.png");
  border-image-slice: 49% 49% 49% 49% fill;
  border-image-width: 10px 10px 10px 10px;
  padding: 0.4em 0.8em;
}

</style>
